/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./header.scss";
import "./mobile.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faSignOutAlt,
  faCheck,
  faTimes,
  faKey,
  faHome,
  faHeart,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import UrlCollect from "../../common/url-collect";
import ViLanguage from "../../languages/vi";
import {
  DomainUserSide,
  DomainAdminSide,
  APIUrlDefault,
  TokenKey,
  getUserInfo,
  removeCookies,
  setCookies,
  getCookies,
} from "../../utils/configuration";
import * as statementActions from "../../redux/store/statement/statement.store";
import * as documentActions from "../../redux/store/document/document.store";
import * as appActions from "../../core/app.store";
import * as acountAction from "../../redux/store/account/account.store";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import {
  NotificationMessageType,
} from "../../utils/configuration";
import { ModalAuthen } from "../auth/modalAuthen";
import Collapse from 'react-bootstrap/Collapse';
import CloseButton from 'react-bootstrap/CloseButton';

const LanguageCollect = ViLanguage;

function RenderModalComponent(props) {
  const { infoAcount, isShowUserInfoModal, handleCloseUserInfoModal, onLogout, showLoading } = props;
  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [isShowChangePassword, setIsShowChangePassword] = useState(false);
  const { register, handleSubmit, errors, getValues, watch, clearErrors, setError } = useForm({ mode: "all", reValidateMode: "onBlur" });

  const password = useRef({});
  password.current = watch("password", "");

  const onChangePassword = () => {
    const { password, confirmPassword } = getValues();
    password === confirmPassword ? clearErrors(["confirmPassword"]) : setError("confirmPassword", { type: "validate" })
  }

  const onSubmit = (data) => {
    clearErrors();
    if (!data.oldPassword && !data.password && !file) { isShowChangePassword && setIsShowChangePassword(!isShowChangePassword); handleCloseUserInfoModal(); return };
    if (data.oldPassword && !data.password) {
      setError("password", { type: "validate" });
      return;
    }
    if (data.password && !data.oldPassword) {
      setError("oldPassword", { type: "validate" });
      return;
    }
    showLoading(true);
    const value = {
      id: infoAcount.id,
      currentPassword: data.oldPassword,
      newPassword: data.password,
      file: avatar
    }
    acountAction.UpdateUserAccount(value).then((res) => {
      if (res && res.content) {
        if (data.oldPassword && data.password) {
          ShowNotification(
            viVN.Success.ChangePasswordSuccess,
            NotificationMessageType.Success
          );
        } else {
          ShowNotification(
            viVN.Success.ChangeAvatarSuccess,
            NotificationMessageType.Success
          )
        }
      }
      isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
      handleCloseUserInfoModal();
      showLoading(false);
    }).catch((err) => {
      showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error)
    })
  }
  const handleChangeImage = (event) => {
    if (event.target.files[0]) {
      setFile(URL.createObjectURL(event.target.files[0]));
      setAvatar(event.target.files[0]);
    }
  }
  const handleOnCloseModal = () => {
    isShowChangePassword && setIsShowChangePassword(!isShowChangePassword);
    handleCloseUserInfoModal();
  }
  useEffect(() => {
    setFile(null);
  }, [isShowUserInfoModal])
  return (
    <Modal
      show={isShowUserInfoModal}
      onHide={handleOnCloseModal}
      backdrop="static"
      keyboard={false}
      centered
      size="xl"
      className="modal-user-info"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">Thông tin người dùng</h5>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-4 text-center">
              {!file ? <img
                src={(infoAcount.avatar || infoAcount.avatar !== "null") ? APIUrlDefault + infoAcount.avatar : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                alt="Avatar"
                className="img-fluid rounded-circle img-avatar"
              /> : <img
                src={file ? file : "https://www.edmundsgovtech.com/wp-content/uploads/2020/01/default-picture_0_0.png"}
                alt="Avatar"
                className="img-fluid rounded-circle img-avatar"
              />
              }
              <div className="position-relative">
                <button className="btn btn-change-avatar w-75 border mt-4 text-uppercase font-weight-bold">
                  Thay ảnh
                </button>

                <input
                  type="file"
                  name="mediaFile"
                  accept=".png, .jpg, .jpeg"
                  className="media-file"
                  onChange={handleChangeImage}
                ></input>
              </div>
            </div>
            <div className="col-8">
              <h5 className="text-uppercase">
                <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
                Thông tin chung
              </h5>
              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Tài khoản</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Họ và tên</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.fullName}
                  />
                </div>
              </div>

              <div className="form-group row no-gutters">
                <label className="col-3 col-form-label">Email</label>
                <div className="col-9">
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={infoAcount.email}
                  />
                </div>
              </div>

              <h5 className="text-uppercase cursor-pointer" onClick={() => setIsShowChangePassword(!isShowChangePassword)}>
                <FontAwesomeIcon icon={faKey} size="sm" className="mr-2" />
                Đổi mật khẩu
              </h5>
              {isShowChangePassword && (<div>
                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu cũ</label>
                  <div className="col-9">
                    <input type="password" name="oldPassword" onChange={() => clearErrors(["oldPassword"])} placeholder="Nhập mật khẩu" ref={register} className="form-control" />
                    {errors.oldPassword && errors.oldPassword.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">Mật khẩu mới</label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        minLength: 8,
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,}$/,
                      })}
                      onChange={onChangePassword}
                      name="password"
                      className="form-control"
                      placeholder="Nhập mật khẩu mới"
                    />
                    {errors.password && errors.password.type === "validate" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.password && errors.password.type === "minLength" && (
                      <span className="error">Mật khẩu tối thiểu 8 ký tự</span>
                    )}
                    {errors.password && errors.password.type === "pattern" && (
                      <span className="error">
                        Có it nhất 1 ký tự viết thường, hoa và ký tự đặc biệt
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-group row no-gutters">
                  <label className="col-3 col-form-label">
                    Nhập lại mật khẩu mới
                  </label>
                  <div className="col-9">
                    <input
                      type="password"
                      ref={register({
                        validate: (value) =>
                          value === password.current || "Mật khẩu không trùng khớp",
                      })}
                      name="confirmPassword"
                      className="form-control"
                      placeholder="Nhập lại mật khẩu mới"
                    />
                    {errors.confirmPassword &&
                      errors.confirmPassword.type === "validate" && (
                        <span className="error">Mật khẩu không khớp</span>
                      )}
                  </div>
                </div>
              </div>)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleOnCloseModal} variant="secondary" >
            <FontAwesomeIcon icon={faTimes} size="sm" className="mr-2" />
            Hủy
          </Button>
          <Button type="submit" variant="primary" >
            <FontAwesomeIcon icon={faCheck} size="sm" className="mr-2" />
            Lưu
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

class HeaderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: getUserInfo() ? true : false,
      planningType: [],
      isShowUserInfoModal: false,
      infoAcount: null,
      showModalAuthen: false,
      showTabLogin: false,
      showTabRegister: false,
      windowWidth: null,
      openMenu: false,
      isShowFormLogin: false,
      listLookupDistrict: [],
      open: false,
      tcqh: null,
      sub_tcqh: null,
      isAdmin: true,
    };
  }

  componentWillMount() {
    this.getAllPlanningType();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps)
    if (nextProps.isShowFormLogin !== this.state.isShowFormLogin) {
      if (nextProps.isShowFormLogin) {
        ShowNotification(
          viVN.Warning.NeedToLoginToAccess,
          NotificationMessageType.Warning
        );
        this.setState({ isShowFormLogin: nextProps.isShowFormLogin }, () => {
          this.handleOpenLogin();
        })
      }
    }
    if (nextProps.isShowFormLogin !== this.state.isShowFormLogin) {
      if (nextProps.isShowFormLoginQLHS) {
        ShowNotification(
          viVN.Warning.NeedToLoginAdminToAccess,
          NotificationMessageType.Warning
        );
        this.setState({ isShowFormLogin: nextProps.isShowFormLogin }, () => {
          this.handleOpenLogin();
        })
      } else {
        if (nextProps.isAdmin !== this.state.isAdmin) {
          if (this.props.isRedirectHome) {
            ShowNotification(
              viVN.Warning.NeedToLoginAdminToAccess,
              NotificationMessageType.Warning
            );
          }
        }
      }
    }
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  });

  handleOutsideClick = () => {
    const className = document.getElementById("navbarNav").className
    if (this.state.openMenu &&
      className.includes('show') &&
      !this.state.tcqh?.classList.contains('show')
      && !this.state.sub_tcqh?.classList.contains('show')) {
      this.setState({ openMenu: false })
    }
  }

  componentDidMount() {
    this.getLookupDistrict();
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('click', this.handleOutsideClick)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('click', this.handleOutsideClick)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      this.setState({
        isLogin: this.props.isLoggedIn
      })
    }
  }

  getUserAccountDetail() {
    this.props.showLoading(true);
    acountAction.GetUserAccountDetail().then((res) => {
      this.setState({ infoAcount: res && res.content ? res.content : null })
      this.props.showLoading(false);
    }).catch((err) => {
      this.props.showLoading(false);
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error
      );
      console.log("err", err);

    });
  }

  getAllPlanningType() {
    this.props.showLoading(true);
    this.props
      .getAllPlanningType()
      .then((res) => {
        this.setState({
          planningType:
            res && res.content && res.content && res.content.length > 0
              ? res.content
              : [],
        });
        this.props.showLoading(false);
      })
      .catch((err) => {
        this.props.showLoading(false);
        console.log("err", err);
        ShowNotification(
          viVN.Errors[(err && err.errorType)],
          NotificationMessageType.Error
        );
      });
  }

  getLookupDistrict() {
    documentActions.GetLockUpDistrict().then((res) => {
      this.setState({
        listLookupDistrict: res && res.content ? res.content : []
      })
    }).catch((err) => {
      ShowNotification(
        viVN.Errors[(err && err.errorType)],
        NotificationMessageType.Error
      );
    });
  };

  onLogout() {
    removeCookies(TokenKey.token);
    removeCookies(TokenKey.refreshToken);
    setCookies(TokenKey.returnUrl, window.location.href)
    window.location.replace(DomainUserSide);
    setCookies("SHOW_FORM_LOGIN", true);
    setCookies("IS_ADMIN", false);
    this.props.logout();
  }

  //--- User info modal
  handleOpenUserInfoModal = () => {
    this.getUserAccountDetail();
    this.setState({
      isShowUserInfoModal: true,
    });
  };

  handleCloseUserInfoModal = () => {
    this.setState({
      isShowUserInfoModal: false,
    });
  };

  onToggleModalAuthen = () => {
    this.setState({ showModalAuthen: !this.state.showModalAuthen }, () => {
      if (!this.state.showModalAuthen) {
        this.props.setIsCancelLogin && this.props.setIsCancelLogin(true);
      }
    })
  }

  toggleSubMenuDistrict = (data) => {
    if (data?.classList.contains('show')) {
      this.state.tcqh.classList.add("show");
      data.classList.remove("show");
    } else {
      data.classList.add("show");
    }
  }

  handleToggleSubMenuDistrict = () => {
    this.toggleSubMenuDistrict(this.state.sub_tcqh);
  }

  handleOpenRegister = () => {
    this.onToggleModalAuthen();
    this.setState({ showTabRegister: true, showTabLogin: false });
  }
  handleOpenLogin = () => {
    this.onToggleModalAuthen();
    this.setState({ showTabLogin: true, showTabRegister: false });
  }

  onClickTraCuuQuyHoach = () => {
    var tcqh = document.getElementById("TraCuuQuyHoach");
    var sub_tcqh = document.getElementById("SubTraCuuQuyHoach");
    this.setState({ tcqh, sub_tcqh });
  }

  render() {
    const PATHNAME = this.props.match.path;
    // const isAdmin = this.props.user && this.props.user.userType ? 
    //                 this.props.user.userType === "ADMINUSER" :
    //                 this.props.user && this.props.user.userRole === "ADMIN";
    const isAdmin = getCookies("IS_ADMIN") === "true" ? true : false;
    const { showModalAuthen, showTabLogin, showTabRegister, windowWidth, openMenu, listLookupDistrict } = this.state;

    return (
      <>
        <div
          id="header_banner"
          className="header_banner"
          onClick={() => {
            const invisLink = document.getElementById('invis-link-header');
            invisLink.click();
          }} style={{ cursor: 'pointer' }}
        >
          <div className="artboard_left">
            <img src={require('../../assets/image/logo.png')} className="d-inline" />
            <div className="artboard_left_title">
              <h6 className="">Cổng thông tin</h6>
              <p>
                Lấy ý kiến điều chỉnh tổng thể quy hoạch chung<br />
                <span>Thành phố Biên Hòa, Tỉnh Đồng Nai</span> đến năm 2045
              </p>
            </div>
          </div>

          <div className="img_browser">
            <img src={require('../../assets/image/Artboard 2.png')} alt="" />
          </div>
        </div>
        <div className="header-container" id="header-map-page-container">
          <div className="container">
            <div className="row">
              <nav
                className="navbar navbar-expand-lg navbar-light w-100 justify-content-end"
                style={{ zIndex: 999 }}
              >
                <button
                  className="navbar-toggler bg-white pl-1 pr-1 pt-0 pb-0"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-end"
                  id="navbarNav"
                >
                  <ul className="navbar-nav w-100 justify-content-between">
                    <li className="nav-item padding-left-menu">
                      <Link
                        to="/"
                        className={`nav-link ${PATHNAME === "/" ? "active" : ""}`}
                      >
                        {LanguageCollect.header_homePage}{" "}
                        <span className="sr-only">(current)</span>
                      </Link>
                    </li>

                    {/* <li className="nav-item dropdown">
                      <a
                        className={`nav-link dropdown-toggle ${PATHNAME.includes("/ban-do-quy-hoach") ||
                          PATHNAME === "/tong-hop-quy-hoach"
                          ? "active"
                          : ""
                          }`}
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {LanguageCollect.header_lookUpPlanning}
                      </a>
                      <div
                        className="dropdown-menu animate slideIn"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <Link
                          to={UrlCollect.SyntheticPlanning}
                          className="dropdown-item"
                        >
                          {LanguageCollect.header_syntheticPlanning}
                        </Link>
                        {this.state.planningType &&
                          this.state.planningType.length > 0 &&
                          this.state.planningType.map((item, index) => (
                            <Link
                              key={index}
                              to={`${UrlCollect.PlanningMap}?key=${item.id}`}
                              className="dropdown-item"
                            >
                              {item.name}
                            </Link>
                          ))}
                      </div>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to="/ban-do-quy-hoach/57"
                        className={`nav-link ${PATHNAME.includes("/ban-do-quy-hoach") ||
                          PATHNAME === "/tong-hop-quy-hoach"
                          ? "active"
                          : ""
                          }`}
                      >
                        {LanguageCollect.header_lookUpPlanning}
                      </Link>
                    </li>
                    <li className="nav-item">
                      {/* <Link
                        to="/y-kien-cong-dong"
                        className={`nav-link ${PATHNAME === "/y-kien-cong-dong" ? "active" : ""
                          }`}
                      > */}
                      <Link
                        to="/xin-y-kien/20"
                        className={`nav-link ${PATHNAME.includes("/xin-y-kien") ? "active" : ""
                          }`}
                      >
                        {LanguageCollect.header_pleaseConsultTheCommunity}
                      </Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                      <a
                        className={`nav-link dropdown-toggle ${PATHNAME.includes("/tin-tuc") ||
                          PATHNAME === "/cong-bo-quy-hoach" || PATHNAME === "/tin-tuc"
                          ? "active"
                          : ""
                          }`}
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {LanguageCollect.header_news}
                      </a>
                      <div
                        className="dropdown-menu animate slideIn"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <Link
                          to="/cong-bo-quy-hoach"
                          className="dropdown-item"
                        >
                          {LanguageCollect.header_planningAnnouncement}
                        </Link>
                        <Link
                          to="/tin-tuc"
                          className="dropdown-item"
                        >
                          {LanguageCollect.header_news}
                        </Link>
                      </div>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        to="/tin-tuc"
                        className={`nav-link ${PATHNAME === "/tin-tuc" ? "active" : ""
                          }`}
                      >
                        {LanguageCollect.header_news}
                      </Link>
                    </li>

                    <li className="nav-item" hidden={!this.state.isLogin}>
                      <Link
                        to="/quan-ly-ho-so"
                        className={`nav-link ${PATHNAME === "/quan-ly-ho-so" ? "active" : ""
                          }`}
                      >
                        {LanguageCollect.header_recordsManager}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/lien-he"
                        className={`nav-link ${PATHNAME === "/lien-he" ? "active" : ""
                          }`}
                      >
                        {LanguageCollect.header_Contact}
                      </Link>
                    </li>
                    <li className="nav-item dropdown pr-lg-0">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <FontAwesomeIcon color="white" icon={faUser} />
                      </a>
                      <div
                        className="dropdown-menu animate slideIn"
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        <Link
                          to={UrlCollect.Login}
                          className="dropdown-item"
                          hidden={this.state.isLogin}
                          onClick={this.handleOpenLogin}
                        // to="/"
                        >
                          {LanguageCollect.header_logIn}
                        </Link>

                        <a
                          className="dropdown-item"
                          href={DomainAdminSide}
                          hidden={!this.state.isLogin}
                        >
                          <FontAwesomeIcon
                            color="white"
                            icon={faLock}
                            className="mr-2"
                          />
                          {LanguageCollect.header_administration}
                        </a>

                        <a
                          className="dropdown-item"
                          hidden={!this.state.isLogin}
                          onClick={this.onLogout}
                        >
                          <FontAwesomeIcon
                            color="white"
                            icon={faSignOutAlt}
                            className="mr-2"
                          />
                          {LanguageCollect.header_logout}
                        </a>

                        <a
                          className="dropdown-item"
                          hidden={!this.state.isLogin}
                          onClick={this.handleOpenUserInfoModal}
                        >
                          <FontAwesomeIcon
                            color="white"
                            icon={faUser}
                            className="mr-2"
                          />
                          {LanguageCollect.header_account}
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          {this.state.infoAcount && (
            < RenderModalComponent infoAcount={this.state.infoAcount} showLoading={this.props.showLoading} onLogout={this.onLogout} isShowUserInfoModal={this.state.isShowUserInfoModal} handleCloseUserInfoModal={this.handleCloseUserInfoModal} />
          )}
          <ModalAuthen
            show={showModalAuthen}
            onHide={this.onToggleModalAuthen}
            showTabRegister={showTabRegister}
            showTabLogin={showTabLogin}
            returnUrl={this.props.returnUrl}
          />
        </div>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  isLoggedIn: state.account.isLoggedIn,
  user: state.account.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getAllPlanningType: statementActions.PlanningTypeDispatch,
      logout: acountAction.logout,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HeaderView);
