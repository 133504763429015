/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-slick";

import { geolocated } from "react-geolocated";
import { AsyncPaginate } from "react-select-async-paginate";

import * as homePageStore from "../../redux/store/home-page/home-page.store";
import * as appActions from "../../core/app.store";
import NewsHome from './news/news.view';
import NewsCategoryView from "./news-category/news-category.view";
import PctCommunityHome from "./please-consult-the-community/please-consult-the-community.view";
import LookupPlanningHome from "./look-up-planning/lookupPlanning.view";
import UrlCollect from "../../common/url-collect";
import history from "../../common/history";
import {
  APIUrlDefault,
  changeAlias,
  getUserInfo,
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.scss";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";
import * as mapHelper from "../../common/map";
//import { open } from "inspector";
import PlanningTypeView from "./planning-type/planning-type.view";
import * as statementAction from "../../redux/store/statement/statement.store"
import * as apiConfig from "../../api/api-config"
import * as newsActions from "../../redux/store/news/news.store";

function HomeDesktop(props) {
  const { showLoading } = props;

  // const isLogin = getUserInfo() ? true : false;
  const [isLogin, setIsLogin] = useState(props.isLoggedIn);

  const settingSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [local, setLocal] = useState({})
  const [slideShow, setSlideShow] = useState([]);
  const [projectList, setProjectList] = useState();
  const [commentInput, setCommentInput] = useState("");
  const [currentPosition, setCurrentPosition] = useState({
    latitude: null,
    longitude: null,
    altitude: null,
    accuracy: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  });
  const [openSearchPlace, setOpenSearchPlace] = useState(true);
  const [openSearchXy, setOpenSearchXy] = useState(false);
  const [openSearchLatLng, setOpenSearchLatLng] = useState(false);
  const [serivceLink, setSerivceLink] = useState([]);
  const [newsCategoriesModel, setNewsCategoriesModel] = useState([]);

  useEffect(() => {
    onGetData();
    onGetSlideShow();
    getLookupNewsCategories();
    //props.GetAllPlanningBoundariesGeomText();
  }, []);

  const GetSerivceLink = () => {
    showLoading(true);
    statementAction.GetSerivceLink().then(res => {
      if (res && res.content.linkGroup && res.content.linkGroup[0].linkService.length > 0) {
        setSerivceLink(res.content.linkGroup[0].linkService)
      }
      showLoading(false)
    }).catch(err => { showLoading(false) })
  }

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      // onGetSlideShow(),
      onGetAllPlanningByTypeId(),
      onGetCurrentPosition(),
      GetSerivceLink(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetSlideShow = () => {
    return new Promise((resolve, reject) => {
      props
        .getSlideShow()
        .then((res) => {
          setSlideShow((prev) => {
            // res && res.content && res.content.length > 0 ? [...res.content, 'this_string_is_put_here_intentionaly'] : []
            if (res && res.content && res.content.length > 0) {
              res.content.forEach(item => {
                prev.push(item);
              });
            }
            return prev;
          });
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const SearchPlanningByLatLng = (lng, lat) => {
    return new Promise((resolve, reject) => {
      homePageStore.SearchPlanningByLatLng(lng, lat).then((res) => {
        resolve(res);
      })
        .catch((err) => reject(err));
    });
  }

  const getLookupNewsCategories = () => {
    return new Promise((resolve, reject) => {
      newsActions.GetLookupNewsCategories().then((res) => {
        if (res && res.content && res.content.length > 0) {
          setNewsCategoriesModel(res.content);
        } else {
          setNewsCategoriesModel([]);
        }
        resolve(res);
      })
        .catch((err) => reject(err));
    });
  }

  const onGetAllPlanningByTypeId = (id) => {
    return new Promise((resolve, reject) => {
      props
        .getAllPlanningByTypeId(id)
        .then((res) => {
          setProjectList(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                  key: item.planningTypeId,
                  labelTemp: changeAlias(item.name),
                };
              })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const onGetCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentPosition(position.coords);
          resolve(position);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const handleSelectAddress = (address) => {
    homePageStore.GetLatLngPlace(address.label)
      .then((res) => res.result[0])
      .then((result) => result.location)
      .then((latLng) => {
        SearchPlanningByLatLng(latLng.lng, latLng.lat).then((res) => {
          if (res.content === 0) {
            ShowNotification(
              viVN.Errors.CoordinateOutSidePolygon,
              NotificationMessageType.Warning,
              NotificationPosition.Center
            );
            history.push(`${UrlCollect.SyntheticPlanning}?lat=${latLng.lat}&lng=${latLng.lng}&searchType=place`);
          } else {
            history.push(`${UrlCollect.PlanningMapViewDetail}${res.content}?lat=${latLng.lat}&lng=${latLng.lng}&searchType=place`);
          }
        })
      })
      .catch();
  };

  const onChangeProject = (data) => {
    data && history.push(`${UrlCollect.PlanningMap}/${data.value}`);
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const loadOptions = async (search, prevOptions) => {
    await sleep(500);
    let arr = [];
    let filteredOptions;
    let filteredOptions1;
    if (!search) {
      filteredOptions = projectList;
    } else {
      const searchLower = changeAlias(search);

      filteredOptions = projectList.filter(({ labelTemp }) =>
        labelTemp.toLowerCase().includes(searchLower)
      );
      if (!(filteredOptions.length > 0)) {
        let searchArray = searchLower.split(" ");
        filteredOptions1 = projectList.filter(({ labelTemp }) => {
          arr = searchArray.filter((item) => labelTemp.includes(item));
          if (arr.length === searchArray.length) return true;
        });
        const hasMore = filteredOptions.length > prevOptions.length + 10;
        const slicedOptions = filteredOptions1.slice(
          prevOptions.length,
          prevOptions.length + 10
        );

        return {
          options: slicedOptions,
          hasMore,
        };
      }
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };
  const Options = async (search, loadedOptions) => {
    if (search.length >= 4) {
      homePageStore.GetLatLngPlace(search)
        .then((res) => {
          setFilteredSuggestions(res && res.result && res.result.length > 0 ? res.result : [])
        })
        .catch();
    }
    await sleep(500)
    const addressSuggestions = filteredSuggestions.map((item, index) => {
      return ({
        value: index,
        label: item.address
      })
    })
    return {
      options: addressSuggestions,
    }
  }
  const handleOpenLatLng = () => {
    setOpenSearchLatLng(true);
    setOpenSearchPlace(false);
    setOpenSearchXy(false);
  }

  const handleOpenCoordinate = () => {
    setOpenSearchLatLng(false);
    setOpenSearchPlace(true);
    setOpenSearchXy(false);
  };

  const handleOpenPlace = () => {
    setOpenSearchLatLng(false);
    setOpenSearchPlace(false);
    setOpenSearchXy(true);
  };

  const searchCoordinate = (event) => {
    event.preventDefault();
    let typeSearch = openSearchPlace ? "place" : "codinate";
    let value = commentInput?.value?.trim();
    let coordinateList = value?.split(",");
    if (coordinateList && coordinateList.length === 2) {
      let x = parseFloat(coordinateList[0]);
      let y = parseFloat(coordinateList[1]);
      console.log('lat', x, 'lng', y)

      if (openSearchXy === true) {
        showLoading(true);
        homePageStore.GetVn2000ToWgs84(y, x).then((res) => {
          SearchPlanningByLatLng(res[0], res[1]).then((result) => {
            if (result.content === 0) {
              ShowNotification(
                viVN.Errors.CoordinateOutSidePolygon,
                NotificationMessageType.Warning,
                NotificationPosition.Center
              );
              showLoading(false);
              history.push(`${UrlCollect.SyntheticPlanning}?lat=${res[1]}&lng=${res[0]}&searchType=place`);
            } else {
              showLoading(false);
              history.push(`${UrlCollect.PlanningMapViewDetail}${result.content}?lat=${res[1]}&lng=${res[0]}&searchType=place`);
            }
          })
        })
      }
      if (openSearchLatLng === true) {
        SearchPlanningByLatLng(y, x).then((res) => {
          if (res.content === 0) {
            ShowNotification(
              viVN.Errors.CoordinateOutSidePolygon,
              NotificationMessageType.Warning,
              NotificationPosition.Center
            );
            showLoading(false);
            history.push(`${UrlCollect.SyntheticPlanning}?lat=${x}&lng=${y}&searchType=place`);
          } else {
            showLoading(false);
            history.push(`${UrlCollect.PlanningMapViewDetail}${res.content}?lat=${x}&lng=${y}&searchType=place`);
          }
        }).catch()
      }
    }
  };

  const classnames = (...args) => {
    const classes = [];
    args.forEach((arg) => {
      if (typeof arg === "string") {
        classes.push(arg);
      } else if (typeof arg === "object" && arg !== null) {
        Object.keys(arg).forEach((key) => {
          if (arg[key]) {
            classes.push(key);
          }
        });
      } else {
        throw new Error(
          "`classnames` only accepts string or object as arguments"
        );
      }
    });

    return classes.join(" ");
  };

  const handleClickCurrentLocation = () => {
    let currentPositionTemp = currentPosition;

    if (!currentPositionTemp) {
      navigator.geolocation.getCurrentPosition((position) => {
        currentPositionTemp = position;
      })
    }

    if (currentPositionTemp.latitude && currentPositionTemp.longitude) {
      console.log("currentPositionTemp", currentPositionTemp);
      SearchPlanningByLatLng(currentPositionTemp.longitude, currentPositionTemp.latitude).then((res) => {
        if (res.content === 0) {
          ShowNotification(
            viVN.Errors.CoordinateOutSidePolygon,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
          history.push(`${UrlCollect.SyntheticPlanning}`);
        } else {
          history.push(`${UrlCollect.PlanningMapViewDetail}${res.content}?lat=${currentPositionTemp.latitude}&lng=${currentPositionTemp.longitude}&searchType=place`);
        }
      }).catch()
    } else {
      ShowNotification(
        viVN.Errors.OpenCoordinateCurrent,
        NotificationMessageType.Error,
        NotificationPosition.TopRight
      );
    }
  };


  const [inputPlaceSearch, setInputPlaceSearch] = useState("");
  const prevInputPlaceSearchRef = useRef();

  var onInputPlaceSearchChange = (input) => {
    setInputPlaceSearch(input);
  }

  useEffect(() => {
    prevInputPlaceSearchRef.current = inputPlaceSearch;
  }, [inputPlaceSearch])

  const [inputPlanningSearch, setInputPlanningSearch] = useState("");
  const prevInputPlanningSearchRef = useRef();

  var onInputPlanningSearchChange = (input) => {
    setInputPlanningSearch(input);
  }

  useEffect(() => {
    prevInputPlanningSearchRef.current = inputPlanningSearch
  }, [inputPlanningSearch])

  return (
    <div className="home-page">
      {/* <div className="home-search">
        <div className="container">
          <div className="row align-items-center no-gutters" style={{ display: 'none' }}>
            <div className="col-12 col-md-6 search-input-planning">
              <img
                src={require("../../assets/icon/search-circle.svg")}
                alt="search-circle"
                className="custom_img_icon cursor-pointer float-left mr-2 mt-1"
                onClick={() => { }}
                style={{
                  position: 'absolute', zIndex: '1', left: '5px'
                }}
              />
              <AsyncPaginate
                className="react-select-container mr-2"
                classNamePrefix="react-select"
                cacheOptions
                defaultMenuIsOpen={true}
                defaultOptions
                loadOptions={loadOptions}
                onChange={onChangeProject}
                placeholder="    Nhập tên Quy hoạch - Dự án"
                noOptionsMessage={() => "Không có dữ liệu"}
                inputValue={inputPlanningSearch}
                onInputChange={onInputPlanningSearchChange}
                onMenuClose={() => {
                  setInputPlanningSearch(prevInputPlanningSearchRef.current)
                }}
                isClearable
              />
            </div>

            <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0 mb-lg-0">
              <div className="row">
                <div className="col-md-6 d-flex align-items-center justify-content-md-end justify-content-center p-md-0 p-2">
                  <span className="icon_button_wrapper" data-tooltip="Lat-Long">
                    <img
                      style={{ width: '30px', height: '30px', background: 'white' }}
                      src={require("../../assets/icon/iconLatLng.svg")}
                      alt="latlong"
                      className={"custom_img_icon mr-2 cursor-pointer " + (openSearchLatLng === false ? "opacity-4" : "")}
                      onClick={handleOpenLatLng}
                    />
                  </span>
                  <span className="icon_button_wrapper" data-tooltip="XY VN2000">
                    <img
                      src={require("../../assets/icon/planning-name.svg")}
                      alt="coordinate"
                      className={
                        "custom_img_icon mr-2 cursor-pointer " +
                        (openSearchXy === false ? "opacity-4" : "")
                      }
                      onClick={handleOpenPlace}

                    />
                  </span>

                  <span className="icon_button_wrapper" data-tooltip="Theo Địa điểm">
                    <img
                      src={require("../../assets/icon/place.svg")}
                      alt="place"
                      className={
                        "custom_img_icon mr-2 cursor-pointer " +
                        (openSearchPlace === false ? "opacity-4" : "")
                      }
                      onClick={handleOpenCoordinate}
                    />
                  </span>

                  <span className="icon_button_wrapper" data-tooltip="Theo Vị trí hiện tại">
                    <img
                      src={require("../../assets/icon/locate.svg")}
                      alt="locate"
                      className="custom_img_icon cursor-pointer mr-2"
                      onClick={handleClickCurrentLocation}
                    />
                  </span>
                </div>
                <div className="col-md-6 d-flex">
                  <form onSubmit={searchCoordinate} className="w-100">
                    {openSearchXy === true ? (
                      <input
                        type="text"
                        ref={(input) => {
                          setCommentInput(input);
                        }}
                        name="address"
                        placeholder="Nhập toạ độ VN2000 (Toạ độ X, Toạ độ Y)"
                        className="input-search"
                      />
                    ) : openSearchPlace === true ? (
                      <AsyncPaginate
                        className="react-select-container mr-2"
                        classNamePrefix="react-select"
                        cacheOptions
                        defaultMenuIsOpen={true}
                        defaultOptions
                        loadOptions={Options}
                        onChange={handleSelectAddress}
                        placeholder="Nhập địa điểm tra cứu"
                        noOptionsMessage={() => "Không có dữ liệu"}
                        inputValue={inputPlaceSearch}
                        onInputChange={onInputPlaceSearchChange}
                        onMenuClose={() => {
                          setInputPlaceSearch(prevInputPlaceSearchRef.current)
                        }}
                      />
                    ) : (
                      <input
                        type="text"
                        ref={(input) => {
                          setCommentInput(input);
                        }}
                        name="address"
                        placeholder="Nhập toạ độ swg84 (Toạ độ Lat, Toạ độ Lng)"
                        className="input-search"
                      />)}

                  </form>
                  {
                    !openSearchPlace &&
                    <span className="icon_button_wrapper" data-tooltip="Tìm kiếm" onClick={searchCoordinate}>
                      <img
                        src={require("../../assets/icon/search-circle.svg")}
                        alt="search-circle"
                        className="custom_img_icon float-left mr-2 mt-1 cursor-pointer ml-2"
                      />
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {slideShow && slideShow.length > 0 && (
        <div id="slider-home-page" className="slider-home-page">
          <Slider {...settingSlider}>
            {slideShow.map((item, index) => {
              if (item.status) return (
                <div className={`home-map ${index == 4 ? 'bot' : ''}`} key={index}>
                  {item.link ? (
                    <a href={item.link} target="_blank">
                      <img src={APIUrlDefault + item.avatar} alt="Map" />
                    </a>
                  ) : (
                    <img src={APIUrlDefault + item.avatar} alt="Map" />
                  )}
                </div>
              )
            })}
          </Slider>
        </div>
      )}

      <div className="home-content">
        <div className="container">
          <div className="row content justify-content-between">
            <div className="col-12 col-md-4 col-lg-4 item">
              <Link to="/xin-y-kien/20">
                <div className="item-block">
                  <img
                    src={require("../../assets/icon/xin-y-kien.png")}
                    alt="item1"
                    className=""
                  />
                  <span >Nội dung xin ý kiến</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4 col-lg-4 item">
              <Link to="/ban-do-quy-hoach/57">
                <div className="item-block">
                  <img
                    src={require("../../assets/icon/tra-cuu-qh.png")}
                    alt="item3"
                    className=""
                  />
                  <span >Bản đồ quy hoạch</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-4 col-lg-4 item">
              <Link to="quan-ly-ho-so">
                <div className="item-block">
                  <img
                    src={require("../../assets/icon/quan-ly-hs.png")}
                    alt="item4"
                    className=""
                  />
                  <span >Hồ sơ quy hoạch</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <PlanningTypeView /> */}
      {/* <LookupPlanningHome /> */}
      {/* <PctCommunityHome /> */}
      <div>
        {newsCategoriesModel.length > 0 && newsCategoriesModel.map((item, index) => (
          <NewsCategoryView title={item.name} categoryId={item.id} index={index} />
        ))}
      </div>
      {/* <NewsHome /> */}
      <section>
        {/* <section style={{ background: '#EEEEEE' }}> */}
        <div className="container">
          <div className="home_link_group">
            <div className="row">
              {serivceLink && serivceLink.length > 0 && serivceLink.map((item, index) => (
                <div className="col-md-4 linkService">
                  <a href={item.link} target="_blank" >
                    <img src={apiConfig.api + item.image} alt={item.name} />
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  isLoggedIn: state.account.isLoggedIn,
  listPolygon: state.mapData.listPolygon,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getSlideShow: homePageStore.GetSlideShow,
      getAllPlanningByTypeId: homePageStore.GetAllPlanningByTypeId,
      GetAllPlanningBoundariesGeomText:
        MapDataStore.GetAllPlanningBoundariesGeomText,
    },
    dispatch
  );

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(HomeDesktop));
