import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import * as appActions from "../../../core/app.store";
import * as newsActions from "../../../redux/store/news/news.store";

import {
    APIUrlDefault,
    getUserInfo,
} from "../../../utils/configuration";
import dateformat from "dateformat";

function NewsCategory(props) {
    const { showLoading, categoryId = undefined, title, index } = props;

    const [newsModel, setNewsModel] = useState([]);
    const [hotNews, setHotNews] = useState({});
    const [pagingNews, setPagingNews] = useState();
    const [currentPage, setCurrentPage] = useState();
    const pageSize = 5;

    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetListNews({
                pageIndex: 1,
                pageSize: pageSize,
                categoryId: categoryId,
            }),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

    const onGetListNews = (data) => {
        return new Promise((resolve, reject) => {
            newsActions.GetListNews(data).then(
                (res) => {
                    console.log(res);
                    setNewsModel(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    setPagingNews(res.content);
                    setCurrentPage(res.content.pageIndex);
                    setHotNews(res.content.items[0]);

                    resolve(res);
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }
    const getNextPage = () => {
        let nextPage = 1;
        if (currentPage > 0) {
            nextPage = currentPage + 1;
        }
        onGetListNews({
            pageIndex: nextPage,
            pageSize: pageSize,
            categoryId: categoryId,
        })
    }

    const getPreviousPage = () => {
        let prePage = 1;
        if (currentPage > 1) {
            prePage = currentPage - 1;
        }
        onGetListNews({
            pageIndex: prePage,
            pageSize: pageSize,
            categoryId: categoryId,
        })
    }
    if (newsModel.length > 0) return (
        // <section className="news" style={{backgroundColor: index % 2 ? '#fff' : '#eee'}}>
        <section className="news pb-1" >
            <div className="container">
                <div className="title title_custom">
                    <div className="row">
                        <div className="col news_category_header">
                            <Link to={`/tin-tuc/${categoryId}`}>
                                <p>{title}</p>
                            </Link>
                            <div className="new-nav pr-3">
                                {
                                    pagingNews && pagingNews.hasPreviousPage && (
                                        <a className="mr-3" onClick={getPreviousPage}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="icon-link" />
                                        </a>
                                    )
                                }

                                <b>{pagingNews && pagingNews.pageIndex} / {pagingNews && pagingNews.pageCount}</b>

                                {pagingNews && pagingNews.hasNextPage && (
                                    <a className="ml-3" onClick={getNextPage}>
                                        <FontAwesomeIcon icon={faArrowRight} className="icon-link" />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hot_news_wrapper p-3">
                    <div className="news_category">
                        <div className="row">
                            <div className="col-lg-6">
                                {hotNews && hotNews.id > 0 && (
                                    <div className="hot-new">
                                        <div className="hot_news_header">
                                            <div className="new-image_">
                                                <Link to={"/tin-tuc-chi-tiet/" + hotNews.id}>
                                                    <img
                                                        className="img-news"
                                                        src={APIUrlDefault + hotNews.image_Url}
                                                        onError={(e) =>
                                                            (e.target.src = require("../../../assets/image/noImage.png"))
                                                        }
                                                        alt={hotNews.title}
                                                    />
                                                </Link>
                                            </div>
                                            <a href={"/tin-tuc-chi-tiet/" + hotNews.id} className="new-title">{hotNews.title}</a>
                                            {/* <div className="new-date">{dateformat(hotNews.created_date, "dd/mm/yyyy")}</div> */}
                                        </div>
                                        <p className="new-desc_" >{hotNews.description}</p>
                                        {/* <Link to={"/tin-tuc-chi-tiet/" + hotNews.id} className="view-detail">Xem thêm
                        <svg width="19" height="14" viewBox="0 0 19 14" fill="none"
                                                              xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.842842 7.83347L16.029 7.83355L12.0238 11.6689C11.7037 11.9754 11.701 12.4812 12.0178 12.7911C12.3347 13.1009 12.8491 13.1036 13.1692 12.7971L18.5779 7.61782C18.898 7.3113 18.9007 6.81345 18.5838 6.50358L13.2382 1.25963C12.9214 0.94976 12.4069 0.947047 12.0869 1.25356C11.7668 1.56008 11.7641 2.05793 12.081 2.3678L16.0373 6.25307L0.851179 6.25298C0.40206 6.25061 0.0327228 6.60428 0.0304302 7.03892C0.0281376 7.47355 0.393723 7.8311 0.842842 7.83347Z"
                            fill="#1075BD"/>
                      </svg>
                      </Link> */}
                                    </div>
                                )}
                            </div>
                            <div className="col-lg-6">
                                <div className="row other-news_">
                                    {newsModel && newsModel.length > 0 && (
                                        newsModel.slice(1).map((item, index) => (
                                            <div key={index} className="new-item_ col-12">
                                                <FontAwesomeIcon icon={faChevronRight} className="icon-link_" />
                                                <div className="new-title">
                                                    <Link to={"/tin-tuc-chi-tiet/" + item.id}>{item.title}</Link>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    else return null;
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    listPolygon: state.mapData.listPolygon
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default (connect(mapStateToProps, mapDispatchToProps)(NewsCategory));
