import React from "react";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faPhoneAlt,
  faEnvelope,
  faAddressCard,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import Vilanguage from "../../languages/vi";
import { DomainUserSide } from "utils/configuration";

const LanguageDisplay = Vilanguage;

export default class FooterView extends React.Component {
  render() {
    return (
      <>
        <div className="footer-container">
          <div className="container">
            <h5 className="text-uppercase mb-3">
              {LanguageDisplay.footer_titleName}
            </h5>
            <div className="mb-1">
              <FontAwesomeIcon
                icon={faMapMarkedAlt}
                color="white"
                className="mr-2"
              />{" "}
              <span>Cơ quan chủ quản: Phòng Quản lý đô thị  thành phố Biên Hòa.</span>
            </div>
            <div className="mb-1">
              <FontAwesomeIcon icon={faAddressCard} color="white" className="mr-2" />{" "}
              <span>Địa chỉ: {LanguageDisplay.footer_address}</span>
            </div>
            <div className="mb-1">
              <FontAwesomeIcon icon={faPhoneAlt} color="white" className="mr-2" />{" "}
              <span>
                Hotline: {LanguageDisplay.footer_hotline}
                {Vilanguage.footer_fax && (
                  <span> / Fax:{Vilanguage.footer_fax}</span>
                )}
              </span>
            </div>
            <div>
              <FontAwesomeIcon icon={faEnvelope} color="white" className="mr-2" />{" "}
              <span>E-mail: {LanguageDisplay.footer_email}</span>
            </div>
            {/* <div className="mb-1">
              <FontAwesomeIcon icon={faBook} color="white" className="mr-2" />{" "}
              <span onClick={() => {
                const invisLink = document.getElementById('invis-link-policy');
                invisLink.click();
              }} style={{ cursor: 'pointer' }}>Điều khoản sử dụng phần mềm</span>
              <span onClick={() => {
                const invisLink = document.getElementById('invis-link-policy-download');
                invisLink.click();
              }} style={{ cursor: 'pointer' }}> /Tải điều khoản sử dụng phần mềm (.pdf)</span>
              <a id="invis-link-policy" style={{ display: 'none' }} href={`${DomainUserSide}/policy`} />
              <a id="invis-link-policy-download" style={{ display: 'none' }} href={`${DomainUserSide}/policy.pdf`} />
            </div> */}
          </div>
        </div>
        <div className="footer_note">
          <div className="container">
            Toàn bộ dữ liệu trên cổng thông tin này chỉ mang tính chất tham khảo, phục vụ quá trình xây dựng dự thảo lập Điều chỉnh tổng thể Quy hoạch thành phố Biên Hòa, tỉnh Đồng Nai đến năm 2045. Nghiêm cấm mọi hành vi sử dụng cổng thông tin vào mục đích khác. Tổ chức, cá nhân chịu hoàn toàn trách nhiệm khi vi phạm.
          </div>
        </div>
      </>
    );
  }
}
