import React from 'react';
import { Router, Switch } from 'react-router-dom';
import RouteComponent from './route-config.jsx';
import history from './common/history';
import UrlCollect from './common/url-collect';
import AppWrapper from './components/app-wrapper/app-wrapper.view';

//--- Loading
import AppLoading from './components/loading/loading.view';

//--- Layout
import LayoutQLHSAuthen from 'components/layout/layout-qlhs.view.jsx';
import LayoutDesktopView from './components/layout/layout.view.jsx';
import LayoutUserDesktopView from './components/layout/layout-user.view.jsx';
import LayoutMapPageView from './components/layout-map-page/layout-map-page.view.jsx';
import LayoutProfileView from './components/layout-profile/layout-profile.view';

//--- Homepage
import HomeDesktop from './modules/home/home.view.jsx';
import RecordsManagementDesktopView from './modules/records-management/records-management.view.jsx';
import ContactDesktopView from './modules/contact/contact.view.jsx';
import LookUpPlanningView from './modules/look-up-planning/look-up-planning.view';
import PlanningMapView from './modules/planning-map-view/planning-map-view.view.tsx';

//-- User
import ResetPasswordDesktop from './modules/reset-password/reset-password.view';
import LoginDesktop from "./modules/login/login.view.jsx";

//--- Xin y kien cong dong
import PleaseConsultTheCommunityDesktopView from './modules/please-consult-the-community/please-consult-the-community.view.jsx';
import PleaseCommentView from './modules/please-consult-the-community/please-comment/please-comment.view.jsx';
import SummaryIdeasView from './modules/please-consult-the-community/summary-ideas/summary-ideas.view.jsx';

//-- Tin tuc

import NewsListView from './modules/news/list.view.jsx';
import NewsDetailView from './modules/news/detail.view.jsx';

//--- Cong bo quy hoach
import PlanningAnnouncementDesktopView from './modules/planning-announcement/planning-announcement.view.jsx';
import PlanningAnnouncementDetailDesktopView from './modules/planning-announcement/planning-announcement-detail/planning-announcement-detail.view.jsx';
import GeneralPlanningView from './modules/general-planning/general-planning.view.tsx';

//--- Error pages
import ErrorPage500 from './modules/error-page/page-500/page-500';
import { UserProfile } from './modules/user-profile/user-profile.js';
import { ChangePassword } from './modules/user-profile/change-password.js';
import { LayoutAuthen } from 'components/layout-authen/index.js';
import Policy from 'modules/policy/policy.view.jsx';

import ReactGA from 'react-ga';
const TRACKING_ID = "G-SDZNVCY612";

function App() {
  ReactGA.initialize(TRACKING_ID);

  return (
    <div>
      <AppLoading />

      <Router history={history}>
        <AppWrapper>
          <Switch>
            {/* Desktop */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={HomeDesktop}
              path={UrlCollect.Home}
            />
            {/* User Desktop */}
            <RouteComponent
              exact
              layout={LayoutUserDesktopView}
              component={LoginDesktop}
              path={UrlCollect.Login}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={PleaseConsultTheCommunityDesktopView}
              path={UrlCollect.PleaseConsultTheCommunity}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={PlanningAnnouncementDesktopView}
              path={UrlCollect.PlanningAnnouncement}
            />
            {/* Tin tuc */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={NewsDetailView}
              path={UrlCollect.NewsDetail + '/:id'}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={NewsListView}
              path={UrlCollect.NewsList}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={NewsListView}
              path={UrlCollect.NewsList + '/:categoryId'}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsDetailView isQHT />}
              path={UrlCollect.NewsDetailQHT + '/:id'}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsListView isQHT />}
              path={UrlCollect.NewsListQHT}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsDetailView isQHCC />}
              path={UrlCollect.NewsDetailQHCC + '/:id'}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsListView isQHCC />}
              path={UrlCollect.NewsListQHCC}
            />

            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsDetailView isQHHTKT />}
              path={UrlCollect.NewsDetailQHHTKT + '/:id'}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={() => <NewsListView isQHHTKT />}
              path={UrlCollect.NewsListQHHTKT}
            />

            <RouteComponent
              exact
              layout={LayoutQLHSAuthen}
              component={RecordsManagementDesktopView}
              path={UrlCollect.RecordsManager}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={ContactDesktopView}
              path={UrlCollect.Contact}
            />
            <RouteComponent
              exact
              layout={LayoutMapPageView}
              component={GeneralPlanningView}
              path={UrlCollect.SyntheticPlanning}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={LookUpPlanningView}
              path={UrlCollect.PlanningMap}
            />
            <RouteComponent
              exact
              layout={LayoutMapPageView}
              component={PlanningMapView}
              path={UrlCollect.PlanningMapViewDetail + ':id'}
            />

            <RouteComponent
              exact
              layout={LayoutUserDesktopView}
              component={ResetPasswordDesktop}
              path={UrlCollect.ResetPassword + '/:userId'}
            />

            {/* Policy */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={Policy}
              path={UrlCollect.Policy}
            />

            {/* Xin y kien cong dong */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={SummaryIdeasView}
              path={UrlCollect.SummaryIdeas}
            />
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={PleaseCommentView}
              path={UrlCollect.PleaseComment + '/:id'}
            />

            {/* Cong bo quy hoach */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={PlanningAnnouncementDetailDesktopView}
              path={UrlCollect.PlanningAnnouncement + '/:id'}
            />

            {/* error pages */}
            <RouteComponent
              exact
              layout={LayoutDesktopView}
              component={ErrorPage500}
              path={UrlCollect.Page500 + ':id'}
            />
            {/* lap */}
            <RouteComponent
              exact
              layout={LayoutProfileView}
              component={UserProfile}
              path={UrlCollect.UserInfo}
            />
            <RouteComponent
              exact
              layout={LayoutProfileView}
              component={ChangePassword}
              path={UrlCollect.ResetPassword}
            />
          </Switch>
        </AppWrapper>
      </Router>
    </div>
  );
}

export default App;
